<template>
  <div class="vx-row">
    <!-- Main Content -->
    <div class="vx-col px-0 w-full lg:w-8/12">
      <div class="vx-row">
        <!-- Reservation Detail -->
        <div class="vx-col px-0 w-full mb-base">
          <vx-card class="">
            <div class="vx-row">
              <!-- Left Side -->
              <div class="vx-col w-full lg:w-6/12 px-0">
                <!-- Guest Information -->
                <div class="vx-row mb-4">
                  <div class="vx-col w-full px-0">
<!--                    <pre>{{ reservations }}</pre>-->
                    <span class="block text-sm mb-3"> Guest Name </span>
                    <h4 class="mb-3">{{ guestName }}</h4>
                    <span class="block text-primary text-base italic mb-1 text-blur cursor-pointer select-none">
                      {{ reservation.guest.email }}
                    </span>
                    <span class="block text-primary text-base italic mb-1 text-blur cursor-pointer select-none">
                      {{ reservation.guest.phone_number }}
                    </span>
                    <span class="block text-base font-medium italic">{{
                      reservation.guest.address
                    }}</span>
                  </div>
                </div>

                <!-- Language -->
<!--                <div class="vx-row mb-4">-->
<!--                  <div class="vx-col w-full">-->
<!--                    <span class="block text-sm"> Language </span>-->
<!--                    <span class="text-base font-medium">{{-->
<!--                      reservation.language-->
<!--                    }}</span>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="vx-row mb-4">-->
                  <!--  Channel -->
<!--                  <div class="vx-col w-full lg:w-6/12">-->
<!--                    <div class="vx-row">-->
<!--                      <div class="vx-col w-full">-->
<!--                        <span class="block text-sm"> Channel </span>-->
<!--                        <span class="text-base font-medium">{{-->
<!--                          reservation.channel-->
<!--                        }}</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->

                  <!-- IATA / TIDS Code -->
<!--                  <div class="vx-col w-full lg:w-6/12">-->
<!--                    <div class="vx-row">-->
<!--                      <div class="vx-col w-full">-->
<!--                        <span class="block text-sm"> IATA / TIDS Code </span>-->
<!--                        <span class="text-base font-medium">{{-->
<!--                          reservation.iata_or_tids_code-->
<!--                        }}</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="vx-row mb-4">
                  <!--  Booked On -->
                  <div class="vx-col w-full lg:w-6/12 px-0">
                    <div class="vx-row">
                      <div class="vx-col w-full px-0">
                        <span class="block text-sm"> Booked On </span>
                        <span class="text-base font-medium">{{
                          $moment(reservations.checkIn).format(
                            "ddd, DD MMM YYYY"
                          )
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Booking Number -->
                  <div class="vx-col w-full lg:w-6/12">
                    <div class="vx-row">
                      <div class="vx-col w-full">
                        <span class="block text-sm"> Booking Number </span>
                        <span class="text-base font-medium">{{
                          reservations.bookingNo
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="vx-row">
                  <!--  Commision amount -->
<!--                  <div class="vx-col w-full lg:w-6/12">-->
<!--                    <div class="vx-row">-->
<!--                      <div class="vx-col w-full">-->
<!--                        <span class="block text-sm"> Commision amount </span>-->
<!--                        <span class="text-base font-medium">{{-->
<!--                          priceFormater(reservation.commission_amount)-->
<!--                        }}</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->

                  <!-- Commision -->
<!--                  <div class="vx-col w-full lg:w-6/12">-->
<!--                    <div class="vx-row">-->
<!--                      <div class="vx-col w-full">-->
<!--                        <span class="block text-sm"> Commission </span>-->
<!--                        <span class="text-base font-medium">{{-->
<!--                          priceFormater(reservation.commission)-->
<!--                        }}</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>

              <!-- Right Side -->
              <div class="vx-col w-full lg:w-6/12">
                <!-- Check In Date -->
                <div class="vx-row mb-4">
                  <div class="vx-col w-full">
                    <span class="block text-sm mb-2"> Check In Date </span>
                    <h4>
                      {{
                        $moment(reservations.checkIn).format(
                          "ddd, DD MMM YYYY"
                        )
                      }}
                    </h4>
                  </div>
                </div>

                <!-- Check Out Date -->
                <div class="vx-row mb-4">
                  <div class="vx-col w-full">
                    <span class="block text-sm mb-2"> Check Out Date </span>
                    <h4>
                      {{
                        $moment(reservations.checkOut).format(
                          "ddd, DD MMM YYYY"
                        )
                      }}
                    </h4>
                  </div>
                </div>

                <!-- Length of Stay -->
                <div class="vx-row mb-4">
                  <div class="vx-col w-full">
                    <span class="block text-sm"> Length of Stay </span>
                    <span class="text-base font-medium">{{ lengthOfStay }}
                      night</span>
                  </div>
                </div>

                <!-- Total Guest -->
                <div class="vx-row mb-4">
                  <div class="vx-col w-full">
                    <span class="block text-sm"> Total Guest </span>
                    <span class="text-base font-medium">{{ totalGuests }}</span>
                  </div>
                </div>

                <!-- Total Unit -->
                <div class="vx-row mb-4">
                  <div class="vx-col w-full">
                    <span class="block text-sm"> Total Units </span>
                    <span class="text-base font-medium">{{
                      reservations.rooms.length
                    }}</span>
                  </div>
                </div>

                <!-- Price -->
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <span class="block text-sm mb-2"> Price </span>
                    <h4>{{ priceFormater(reservations.amount) }}</h4>
                  </div>
                </div>
              </div>

              <div class="vx-col w-full mt-5 px-0">
                <span class="block text-sm mb-1">Note from this guest:</span>
                <ul class="list-disc">
                  <li v-if="reservations.specialRequest.highFloor" class="text-base font-medium italic">
                    High Floor: {{ reservations.specialRequest.highFloor ? 'YES' : 'NO' }}
                  </li>
                  <li v-if="reservations.specialRequest.connectingRoom" class="text-base font-medium italic">
                    Connecting Room: {{ reservations.specialRequest.connectingRoom ? 'YES' : 'NO' }}
                  </li>
                  <li v-if="reservations.specialRequest.checkInTime" class="text-base font-medium italic">
                    Check-in time: {{ reservations.specialRequest.checkInTime }}
                  </li>
                  <li v-if="reservations.specialRequest.checkOutTime" class="text-base font-medium italic">
                    Check-out time: {{ reservations.specialRequest.checkOutTime }}
                  </li>
                  <li v-if="reservations.specialRequest.bedConfig" class="text-base font-medium italic">
                    Bed Configuration: {{ reservations.specialRequest.bedConfig }}
                  </li>
                  <li v-if="reservations.specialRequest.smokingPreference" class="text-base font-medium italic">
                    Smoking Preference: {{ reservations.specialRequest.smokingPreference }}
                  </li>
                  <li v-if="reservations.specialRequest.others" class="text-base font-medium italic">
                    Others: {{ reservations.specialRequest.others }}
                  </li>
                </ul>
              </div>
            </div>
          </vx-card>
        </div>

        <!-- Product Property detail -->
        <div class="vx-col w-full px-0">
          <vx-card>
            <vs-collapse-item>
              <!-- Header -->
              <div slot="header">
                <div class="vx-row mb-3">
                  <div class="vx-col w-6/12">
                    <h5>{{ reservations.roomDetail.name }}</h5>
                  </div>
                  <div class="vx col w-6/12 flex justify-end">
                    <h4 class="mr-base">
                      {{ priceFormater(reservations.amount) }}
                    </h4>
                  </div>
                </div>
                <div class="vx-row">
                  <!-- Check In Date -->
                  <div class="vx-col w-full flex items-center">
                    <div class="mr-base">
                      <vs-icon icon-pack="feather" icon="icon-log-in"></vs-icon>
                      <span class="text-base ml-2">{{
                        $moment(reservations.checkIn).format(
                          "ddd, DD MMM YYYY"
                        )
                      }}</span>
                    </div>
                    <!-- Check Out Date -->
                    <div class="mr-base">
                      <vs-icon
                        icon-pack="feather"
                        icon="icon-log-out"
                      ></vs-icon>
                      <span class="text-base ml-2">{{
                        $moment(reservations.checkOut).format(
                          "ddd, DD MMM YYYY"
                        )
                      }}</span>
                    </div>
                    <!-- Rate Plan Meals -->
                    <div>
                      <vs-icon
                        icon-pack="feather"
                        icon="icon-package"
                      ></vs-icon>
                      <span class="text-base ml-2">
                        {{ reservations.roomDetail.mealPlan }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <vs-divider />
              <!-- Body -->
              <div class="collapse-body pt-5">
                <div class="vx-row">
                  <!-- Body Left Side -->
                  <div class="vx-col w-full lg:w-4/12">
                    <!-- Guest name -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <span class="block text-sm mb-1">Guest Name</span>
                        <h6>{{ reservation.guest.name }}</h6>
                      </div>
                    </div>

                    <!-- Max Occupancy -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <span class="block text-sm mb-1">Max Occupancy</span>
                        <h6>
                          {{ reservation.product_property.max_occupancy }}
                        </h6>
                      </div>
                    </div>

                    <!-- Meal Options -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <span class="block text-sm mb-1">Meal Options</span>
                        <h6>
                          {{ reservation.meals.join(", ") }} (included in rate)
                        </h6>
                      </div>
                    </div>

                    <!-- Room Photo -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <span class="block text-sm mb-1">Room Photo</span>
                        <img
                          class="w-full"
                          :src="reservation.product_property.photo"
                          :alt="reservation.product_property.name"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Body Right Side -->
                  <div class="vx-col w-full lg:w-8/12">
                    <!-- Table Header -->
                    <div class="vx-row">
                      <div class="vx-col w-3/12">
                        <span class="block text-base font-semibold">
                          Date
                        </span>
                      </div>
                      <div class="vx-col w-6/12">
                        <span class="block text-base font-semibold">
                          Rate
                        </span>
                      </div>
                      <div class="vx-col w-3/12">
                        <span class="block text-base font-semibold">
                          Price per night
                        </span>
                      </div>
                    </div>
                    <vs-divider></vs-divider>
                    <!-- Table body -->
                    <div class="vx-row">
                      <!-- Date Check In and Check Out -->
                      <div class="vx-col w-3/12">
                        <span class="block text-base">
                          {{
                            $moment(reservation.check_in_date).format("DD MMM")
                          }}
                          -
                          {{
                            $moment(reservation.check_out_date).format("DD MMM")
                          }}
                        </span>
                      </div>
                      <!-- Rate Plan -->
                      <div class="vx-col w-6/12">
                        <span class="block text-base">
                          {{ reservation.rate_plan }}
                        </span>
                      </div>
                      <!-- Price -->
                      <div class="vx-col w-3/12">
                        <span class="block text-base">{{
                          priceFormater(reservation.price)
                        }}</span>
                      </div>
                    </div>
                    <vs-divider></vs-divider>
                    <!-- Promo -->
                    <div class="vx-row">
                      <div class="vx-col w-full mb-5">
                        <span class="block text-base font-semibold">
                          Promo
                        </span>
                      </div>
                      <div class="vx-col w-full">
                        <div
                          class="vx-row"
                          v-for="(promo, i) in reservation.promos"
                          :key="i"
                        >
                          <div class="vx-col w-8/12">
                            <span class="block text-base">
                              - {{ promo.promo_rate_plan.name }}
                            </span>
                          </div>
                          <div class="vx-col w-4/12 flex justify-end">
                            <span class="block text-base font-semibold">
                              {{
                                getDiscountPrice(
                                  reservation.price,
                                  promo.promo_rate_plan
                                )
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <vs-divider></vs-divider>
                    <!-- Subtotal -->
                    <div class="vx-row">
                      <div class="vx-col w-8/12">
                        <span class="block text-base font-semibold">
                          Subtotal
                        </span>
                      </div>
                      <div class="vx-col w-4/12 text-right">
                        <span class="block text-base font-semibold">
                          {{ priceFormater(reservation.price) }}
                        </span>
                        <span
                          class="block text-base font-semibold"
                          v-for="(promo, i) in reservation.promos"
                          :key="i"
                        >
                          -
                          {{
                            getDiscountPrice(
                              reservation.price,
                              promo.promo_rate_plan
                            )
                          }}
                        </span>
                      </div>
                    </div>
                    <vs-divider></vs-divider>
                    <!-- Total Price -->
                    <div class="vx-row">
                      <div class="vx-col w-9/12">
                        <span class="block text-base font-semibold">
                          Total Price
                        </span>
                      </div>
                      <div class="vx-col w-3/12">
                        <span class="block text-base font-semibold">
                          {{
                            getTotalPrice(reservation.price, reservation.promos)
                          }}
                        </span>
                      </div>
                    </div>
                    <vs-divider></vs-divider>
                    <!-- Tax -->
                    <div class="vx-row">
                      <div class="vx-col w-4/12">
                        <span class="block text-base"> Rate includes </span>
                      </div>
                      <div class="vx-col w-4/12">
                        <span class="block text-base">
                          10% Service charge
                        </span>
                      </div>
                      <div class="vx-col w-4/12">
                        <span class="block text-base"> 10% TAX </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vs-collapse-item>
          </vx-card>
        </div>
      </div>
    </div>

    <!-- Action Group -->
    <div class="vx-col w-full lg:w-4/12">
      <div class="vx-row">
        <div class="vx-row">
          <div class="vx-col w-full">
            <vx-card class="p-5">
              <div class="vx-row">
<!--                <div class="vx-col w-full mb-5">-->
<!--                  <h5>Update this reservation</h5>-->
<!--                </div>-->
<!--                <div class="vx-col w-full mb-4">-->
<!--                  <vs-button class="w-full" type="border" @click="popup = true"-->
<!--                    >Change reservation dates</vs-button-->
<!--                  >-->
<!--                </div>-->
<!--                <div class="vx-col w-full mb-4">-->
<!--                  <vs-button disabled="true" class="w-full" type="border"-->
<!--                    >Mark as no-show</vs-button-->
<!--                  >-->
<!--                </div>-->
<!--                <div class="vx-col w-full mb-4">-->
<!--                  <vs-button disabled="true" class="w-full" type="border"-->
<!--                    >Report guest misconduct</vs-button-->
<!--                  >-->
<!--                </div>-->
<!--                <div class="vx-col w-full mb-4">-->
<!--                  <vs-button class="w-full" type="border"-->
<!--                    >Print this page</vs-button-->
<!--                  >-->
<!--                </div>-->
<!--                <div class="vx-col w-full">-->
<!--                  <vs-button class="w-full" type="border"-->
<!--                    >Request to cancel reservation</vs-button-->
<!--                  >-->
<!--                </div>-->
<!--              </div>-->
<!--              <vs-divider class="py-3" />-->
<!--              <div class="vx-row">-->
<!--                <div class="vx-col w-full mb-5">-->
<!--                  <h5>Payment</h5>-->
<!--                </div>-->
<!--                <div class="vx-col w-full mb-4">-->
<!--                  <vs-button class="w-full" type="border"-->
<!--                    >Mark credit card as invalid</vs-button-->
<!--                  >-->
<!--                </div>-->
<!--                <div class="vx-col w-full mb-5">-->
<!--                  <vs-button class="w-full" type="border"-->
<!--                    >View credit card detail</vs-button-->
<!--                  >-->
<!--                </div>-->
                <div class="vx-col w-full flex items-center mb-4">
                  <vs-chip transparent color="success">{{
                    reservation.payment_status.name
                  }}</vs-chip>
                  <span class="text-base ml-3">{{
                    $moment(reservations.checkIn).format("dddd, DD MMMM YYYY")
                  }}</span>
                </div>
                <div class="vx-col w-full">
                  <span class="mb-2 block text-sm">Payment Status</span>
                  <h4>{{ reservations.status }}</h4>
                </div>
              </div>
            </vx-card>
          </div>
        </div>
      </div>
    </div>

    <!-- Popup -->
    <div class="centerx">
      <vs-popup
        class="holamundo"
        title="Update Reservation Dates"
        :active.sync="popup"
      >
        <div class="vx-row">
          <!-- Check In Date Input -->
          <div class="vx-col w-full lg:w-6/12">
            <span class="block mb-2">Check In Date</span>
            <h6>{{ $moment(reservations.checkIn).format("dddd, DD MMMM YYYY") }}</h6>
          </div>
          <!-- Check Out Date Input -->
          <div class="vx-col w-full lg:w-6/12">
            <span class="block mb-2">Check Out Date</span>
            <h6>{{ $moment(reservations.checkOut).format("dddd, DD MMMM YYYY") }}</h6>
          </div>
          <!-- Save button -->
<!--          <div class="vx-col w-full mt-5 flex justify-end">-->
<!--            <vs-button icon-pack="feather" icon="icon-save" @click="storeData"-->
<!--              >Save Changes</vs-button-->
<!--            >-->
<!--          </div>-->
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

// Store Module
import moduleReservationManagement from "@/store/reservation-management/moduleReservationManagement.js";

export default {
  name: "ReservationDetail",

  metaInfo: {
    title: "Reservation Detail",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    "v-select": vSelect,
    flatPickr,
  },

  computed: {
    reservations() {
      const reservation = localStorage.getItem('reservation');
      if (!reservation) return null;
      return JSON.parse(reservation);
    },
    guestName() {
      return this.reservations.rooms.map(x => `${x.pax.firstName} ${x.pax.lastName}`)
        .join(',');
    },
    lengthOfStay() {
      let days = this.$moment(this.reservations.checkOut).diff(
        this.$moment(this.reservations.checkIn),
        "day"
      );
      if (days === 0) days = 1;
      return days;
    },
    noOfAdults() {
      let count = 0;
      for (const room of this.reservations.rooms) {
        count += room.noOfAdults;
      }
      return count;
    },
    noOfChild() {
      let count = 0;
      for (const room of this.reservations.rooms) {
        count += room.noOfAdults;
      }
      return count;
    },
    totalGuests() {
      return this.noOfAdults + this.noOfChild;
    }
  },

  data() {
    return {
      reservation: {
        booking_number: "",
        guest: {
          name: "",
          email: "",
          phone_number: "",
          address: "",
        },
        language: "Indonesia (ID)",
        check_in_date: null,
        check_out_date: null,
        booked_on: null,
        length_of_stay: null,
        total_guest: 0,
        total_unit: 0,
        channel: "",
        iata_or_tids_code: "FD4837483",
        commission_amount: 0,
        commission: 0,
        price: 0,
        notes: "",

        product_property: {
          name: "",
          photo: "",
          max_occupancy: "",
        },

        payment_status: {},

        promos: [],

        meals: [],
      },

      popup: false,
      payload: {},

      configdateTimePicker: {
        dateFormat: "d-m-Y",
      },

      // Vselect options
      paymentStatusOptions: [],
    };
  },

  methods: {
    priceFormater(price) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      })
        .format(price)
        .split(",")[0];
    },

    getDiscountPrice(price, promo) {
      const discountPrice = price * (promo.discount.slice(0, -1) / 100);
      return this.priceFormater(discountPrice);
    },

    getTotalPrice(price, promos) {
      let totalPromo = 0

      promos.forEach(promo => {
        const promoPrice = Math.round(price * (promo.promo_rate_plan.discount.slice(0, -1) / 100))
        totalPromo += promoPrice
      })

      return this.priceFormater(price - totalPromo)
    },

    getPaymentStatuses() {
      axios
        .get("payment-status")
        .then(({ data: res }) => {
          this.paymentStatusOptions = res.data.map((paymentStatus) => {
            return {
              id: paymentStatus.id,
              name: paymentStatus.name,
            };
          });
        })
        .catch((err) => this.$catchErrorResponse(err));
    },

    getData() {

    },

    // storeData() {
    //   Object.assign(this.payload, { _method: "PUT" });
    //   this.payload.check_in_date = this.payload.check_in_date
    //     .split("-")
    //     .reverse()
    //     .join("-");
    //   this.payload.check_out_date = this.payload.check_out_date
    //     .split("-")
    //     .reverse()
    //     .join("-");
    //
    //   this.$store
    //     .dispatch("reservationManagement/updateReservation", {
    //       id: this.reservation.booking_number,
    //       body: this.payload,
    //     })
    //     .then(({ data: res }) => {
    //       this.$vs.notify({
    //         title: "Successful",
    //         text: res.msg_status,
    //         iconPack: "feather",
    //         icon: "icon-check-circle",
    //         color: "success",
    //       });
    //
    //       this.popup = false;
    //       this.getData();
    //     })
    //     .catch((err) => this.$catchErrorResponse(err));
    // },
  },

  created() {
    if (!moduleReservationManagement.isRegistered) {
      this.$store.registerModule(
        "reservationManagement",
        moduleReservationManagement
      );
      moduleReservationManagement.isRegistered = true;
    }
    console.log(this.reservations);
    if (!this.reservations) {
      this.$router.push('/vancynet/reservations');
    }
    // this.getPaymentStatuses();
    // this.getData();
  },
};
</script>

<style>
.text-blur {
  filter: blur(3px);
  transition: all .3s;
}
.text-blur:hover {
  filter: unset;
}
.list-disc {
  list-style-type: disc;
  padding-left: 1rem;
}
</style>
